import * as DateHelper from "common/modules/dateHelper";
import * as TimeHelper from "common/modules/timeHelper";
import * as Immutable from "immutable";
import * as Moment from "moment-timezone";

import { IDiff, IDiffContainer } from "common/modules/diffHelper";
import { IStationPropertiesValues, StationProperties } from "./station/stationProperties";
import { IStationSettingsValues, StationSettings } from "./station/stationSettings";
import { PersistentStationSettings, IPersistentStationSettingsValues } from "application/models/station/persistentStationSettings";
import * as _ from "lodash";

export interface IStationValues {
    id: number;
    legacyBookingUnitId?: number;
    name: string;
    children?: Immutable.List<IStationValues>;
    subNodes?: IStationValues[];
    businessDateBreakTime?: IDuration;
    validFrom?: IMoment | string;
    validUntil?: IMoment | string;
    parentNodeId?: number;
    timeZone: string;
    properties?: Immutable.List<IStationPropertiesValues> | IStationPropertiesValues[];
    settings?: IStationSettingsValues;
    isActive?: boolean;
    sortOrder?: number;
    persistentSettings?: IPersistentStationSettingsValues;
}

const StationRecord = Immutable.Record({
    id: 0,
    legacyBookingUnitId: null,
    name: null,
    children: Immutable.List<IStation>(),
    businessDateBreakTime: null,
    validFrom: null,
    validUntil: null,
    parentNodeId: 0,
    timeZone: null,
    properties: Immutable.List<StationProperties>(),
    settings: new StationSettings({}),
    isActive: true,
    stationId: -1,
    sortOrder: null,
    persistentSettings: null,
});

let stationAtCache = Immutable.Map<Station, Immutable.Map<string | undefined, Station>>();

export class Station extends StationRecord implements IStationValues {
    readonly id: number;
    readonly legacyBookingUnitId: number;
    readonly name: string;
    readonly children: Immutable.List<Station>;
    readonly businessDateBreakTime: IDuration;
    readonly validFrom: IMoment;
    readonly validUntil: IMoment;
    readonly parentNodeId: number;
    readonly timeZone: string;
    readonly properties: Immutable.List<StationProperties>;
    readonly settings: StationSettings;
    readonly isActive: boolean;
    readonly stationId: number;
    readonly sortOrder: number;
    private _childIds: Immutable.List<number>;
    readonly persistentSettings: PersistentStationSettings;
    constructor(values: IStationValues) {
        if (values.subNodes) {
            values.children = Immutable.List<Station>(values.subNodes.map((s) => (s instanceof Station ? s : new Station(s))));
        } else {
            values.children = Immutable.List<Station>();
        }
        if (values.properties && !Immutable.List.isList(values.properties)) {
            values.properties = Immutable.List<StationProperties>((<IStationPropertiesValues[]>values.properties).map((s) => new StationProperties(s)));
        }
        if (!values.properties) {
            values.properties = Immutable.List();
        }
        if (values.validFrom) {
            values.validFrom = Moment.utc(values.validFrom);
        }
        if (values.validUntil) {
            values.validUntil = Moment.utc(values.validUntil);
        }
        if (values.settings && !(<StationSettings>values.settings).set) {
            values.settings = new StationSettings(values.settings);
        }
        if (values.businessDateBreakTime) {
            values.businessDateBreakTime = Moment.duration(values.businessDateBreakTime);
        }
        values.persistentSettings = new PersistentStationSettings(values.persistentSettings ?? {});
        // @ts-ignore
        values.stationId = values.id;

        super(values);
    }

    setProperties(prop: StationProperties): Station {
        const propIndex = this.properties.findIndex((p) => p.validFrom === prop.validFrom);
        if (propIndex >= 0) {
            return <Station>this.setIn(["properties", propIndex], prop);
        }
        return <Station>this.set("properties", this.properties.push(<StationProperties>prop.set("id", -1)));
    }

    propertiesAtDate(atDate: IMoment): StationProperties {
        const properties = this.properties.filter((p) => !p.validFrom || !p.validFrom.isAfter(atDate, "day")).maxBy((prop) => prop.validFrom);
        if (properties) {
            return properties;
        }
        return <any>this;
    }

    propertiesAtDateOrAfter(atDate: IMoment): Immutable.List<StationProperties> {
        const propAtDate = this.propertiesAtDate(atDate);
        const properties = this.properties
            .filter((p) => p.id !== propAtDate.id && p.validFrom.isSameOrAfter(atDate))
            .toList()
            .insert(0, propAtDate);
        if (properties) {
            return properties;
        }
        return Immutable.List<StationProperties>([this]);
    }

    propertiesInInterval(fromDate: IMoment, toDate: IMoment) {
        let props = this.properties.filter((p) => DateHelper.isDayInInterval(p.validFrom, fromDate, toDate)).toList();
        if (!props.some((p) => p.validFrom.isSame(fromDate, "day"))) {
            props = props.push(<any>this.atDate(fromDate));
        }
        return props;
    }

    atDate(atDate: IMoment | undefined): IStation {
        /*
        Caching strategy:Use a GLOBAL map of cachees for all immutable versions of all stations and AtDates.
        For each station, we have a Map with cache-keys (atDate in y-m-d format) and the cached resolved version of that station at that time.

        So lookup is:
        1: Retrieve cache from GLOBAL cache collection using "this station" as the key (value equality)
        2: check if cache contains atDate

        caveat of this approach: the cache can grow out of hand if we dynamically create new versions of stations and check their atDate, the cache will grow indefinitely: station = station.set("lastRead", new Date).atDate(x). That will add a new station and a new cache for that station.

        We truncate the atDate to a undefined or yy-m-d value because some callers send in now() as atDate.
        */
        let cacheKey = atDate && `${atDate.year()}-${atDate.month()}-${atDate.day()}`;
        let cache = stationAtCache.get(this);
        if (cache) {
            var cached = cache.get(cacheKey);
            if (cached) {
                return cached;
            }
        } else {
            cache = Immutable.Map();
        }

        // filter: De som inte har validfrom eller de som är för atDate, ta den som är senast.
        let stationProps = this.properties.filter((p) => !p.validFrom || !p.validFrom.isAfter(atDate, "day")).maxBy((prop) => prop.validFrom);
        let station: Station;
        if (stationProps) {
            stationProps = <StationProperties>stationProps.set("id", this.id).delete("stationId");
            station = <IStation>this.merge(stationProps);
        } else {
            station = <IStation>this.set("isActive", atDate && !atDate.isBefore(TimeHelper.toSystemTime(this.validFrom), "day"));
        }

        // set the cache so that next lookup does not need to iterate.
        var updatedCache = cache.set(cacheKey, station);
        stationAtCache = stationAtCache.set(this, updatedCache);
        if (stationAtCache.size > 10000) {
            console.warn("stationAtDate cache is has grown too much. Please advise the core team.");
        }
        return station;
    }

    toDTORecord(): IStationDTO {
        let dto = new StationDTO(this);
        dto = <StationDTO>dto.set("validFrom", this.validFrom && this.validFrom.format("YYYY-MM-DD"));
        dto = <StationDTO>dto.set("validUntil", this.validUntil && this.validUntil.format("YYYY-MM-DD"));
        dto = <StationDTO>dto.set("businessDateBreakTime", this.businessDateBreakTime && TimeHelper.durationMomentToHMSString(this.businessDateBreakTime));
        dto = <StationDTO>dto.set(
            "properties",
            this.properties.map((p) =>
                p
                    .set("validFrom", p.validFrom && p.validFrom.format("YYYY-MM-DD"))
                    .set("businessDateBreakTime", p.businessDateBreakTime && TimeHelper.durationMomentToHMSString(p.businessDateBreakTime))
            )
        );
        dto = <StationDTO>dto.set("subNodes", this.children.map((s) => s.toDTORecord()).toList());
        return <IStationDTO>dto;
    }

    childIds(): Immutable.List<number> {
        if (this._childIds) return this._childIds;
        let ids = Immutable.List<number>();
        this.children.forEach((s) => (ids = ids.concat(s.childIds().insert(0, s.id)).toList()));
        this._childIds = ids;
        return ids;
    }

    diff(before: Station | null, prefix: string = "station"): IDiffContainer {
        let diffs: IDiff[] = [];
        const toSkip = ["subNodes", "children", "properties", "id", "stationId", "validFrom", "parentNodeId"];
        //This is currently not used, so we remove it from the diff
        //toSkip.push("businessDateBreakTime");
        if (!before) {
            return {
                entity: this.name,
                diffs: [
                    {
                        key: "station.created",
                    },
                ],
            };
        }
        this.keySeq().forEach((key) => {
            if (toSkip.includes(key)) {
                return true;
            }
            const propAfter = this.get(key);
            const propBefore = before && before.get(key);
            if (propAfter && propAfter.diff) {
                diffs = diffs.concat(propAfter.diff(propBefore, [prefix, key].join(".")));
                return true;
            }
            if (propBefore !== propAfter) {
                diffs.push({ key: prefix + "." + key, before: propBefore, after: propAfter });
            }
            return true;
        });
        return {
            entity: this.name,
            diffs: diffs,
        };
    }
}

export type IStation = Station;

// ----------- StationDTO -----------

export interface IStationDTO {
    id: number;
    legacyBookingUnitId: number;
    name: string;
    subNodes: Immutable.List<IStationDTO>;
    businessDateBreakTime: string;
    validFrom: string | IMoment;
    validUntil: string | IMoment;
    parentNodeId: number;
    timeZone: string;
    properties: Immutable.List<IStationPropertiesValues>;
    settings: IStationSettingsValues;
    sortOrder: number;
    persistentSettings: IPersistentStationSettingsValues;
}

const StationDTORecord = Immutable.Record({
    id: 0,
    legacyBookingUnitId: null,
    name: null,
    subNodes: Immutable.List<IStation>(),
    businessDateBreakTime: null,
    validFrom: null,
    validUntil: null,
    parentNodeId: 0,
    timeZone: null,
    properties: Immutable.List<StationProperties>(),
    settings: new StationSettings({}),
    sortOrder: null,
    persistentSettings: new PersistentStationSettings({}),
});

class StationDTO extends StationDTORecord implements IStationDTO {
    id: number;
    legacyBookingUnitId: number;
    name: string;
    subNodes: Immutable.List<IStationDTO>;
    businessDateBreakTime: string;
    validFrom: string;
    validUntil: string;
    parentNodeId: number;
    timeZone: string;
    properties: Immutable.List<IStationPropertiesValues>;
    settings: IStationSettingsValues;
    sortOrder: number;
    persistentSettings: PersistentStationSettings;
}

export function hasChangesInInterval(stations: Immutable.List<IStation>, startDate: IMoment, endDate: IMoment): boolean {
    let hasChanges = false;

    flattenStations(stations).forEach((station) => {
        station.properties.forEach((prop) => {
            if (prop.validFrom > startDate && prop.validFrom < endDate) {
                hasChanges = true;
            }
        });
    });

    return hasChanges;
}

/**
 * memoize the flattening of stations. Not caching this causes a lot of CPU work in larger systems and weaker computers.
 * This caches the flattening of a a list of stations + children
 */
let flatCacheStations: Immutable.Map<Immutable.List<IStation>, Immutable.OrderedMap<number, IStation>> = Immutable.Map();

export function flattenStations(stations: Immutable.List<IStation>): Immutable.OrderedMap<number, IStation> {
    let cached: Immutable.OrderedMap<number, IStation> = flatCacheStations.get(stations);
    if (cached) {
        return cached;
    }

    let stationList = Immutable.OrderedMap<number, IStation>();
    stations.forEach((station) => (stationList = <Immutable.OrderedMap<number, IStation>>stationList.concat(flattenStation(station))));
    let result = Immutable.OrderedMap<number, IStation>(stationList);

    flatCacheStations = flatCacheStations.set(stations, result);
    return result;
}

/**
 * memoize the flattening of stations. Not caching this causes a lot of CPU work in larger systems and weaker computers.
 * This caches the flattening of a station + children
 */
let flatCacheSingleStation: Immutable.Map<IStation, Immutable.OrderedMap<number, IStation>> = Immutable.Map();

export function flattenStation(station: IStation): Immutable.OrderedMap<number, IStation> {
    let cached: Immutable.OrderedMap<number, IStation> = flatCacheSingleStation.get(station);
    if (cached) {
        return cached;
    }
    let stations = Immutable.OrderedMap<number, IStation>();
    stations = stations.set(station.id, station);
    stations = stations.concat(flattenStations(station.children)).toOrderedMap();

    flatCacheSingleStation = flatCacheSingleStation.set(station, stations);

    return stations;
}

export function getStation(stations: Immutable.List<IStation>, id: number): IStation | undefined {
    const flatStations = flattenStations(stations);
    return flatStations.get(id);
}

export function getStationsAndChildrenIds(stationTrees: Immutable.List<IStation>, stationIds: Immutable.Set<number>) {
    let allStations = Immutable.Set<number>(stationIds);
    if (stationTrees && stationIds) {
        const flattenedStations = flattenStations(stationTrees);
        stationIds.forEach((stationId) => {
            const station = flattenedStations.get(stationId);
            if (station && station.children) {
                const childrenIds = flattenStation(station).keySeq().toSet();
                allStations = allStations.union(childrenIds);
            }
        });
    }
    return allStations;
}

export function isLeafStation(stationTrees: Immutable.List<IStation>, stationId: number): boolean {
    const flattenedStations = flattenStations(stationTrees);
    const station = flattenedStations.get(stationId);
    return station && (!station.children || station.children.size === 0);
}

export function isLeafStationFlattened(flattenedStationMap: Immutable.OrderedMap<number, IStation>, stationId: number): boolean {
    const station = flattenedStationMap.get(stationId);
    return station && (!station.children || station.children.size === 0);
}

export function getParentIds(stationTrees: Immutable.List<IStation>, stationId: number): Immutable.Set<number> {
    return getParentIdsAux(flattenStations(stationTrees), stationId);
}

function getParentIdsAux(stations: Immutable.OrderedMap<number, IStation>, stationId: number): Immutable.Set<number> {
    const station = stations.get(stationId);
    if (station && station.parentNodeId) {
        return getParentIdsAux(stations, station.parentNodeId).add(station.parentNodeId);
    } else {
        return Immutable.Set<number>();
    }
}

export function getStationNames(stations: Immutable.List<IStation>, selectedStations: Immutable.Set<number>, labelWhenAllSelected?: string): string {
    if (labelWhenAllSelected && flattenStations(stations).every((s) => selectedStations.has(s.id))) return labelWhenAllSelected;
    let stationNames = Immutable.List();

    selectedStations.forEach((sid) => {
        const station = getStation(stations, sid);
        if (station) {
            stationNames = stationNames.push(station.name);
        }
    });

    return stationNames.join(", ");
}

export function getVersion(station: IStation, atDate: IMoment | undefined): IStation {
    return station.atDate(atDate);
}

export function getVersionForInterval(station: IStation, fromDateInclusive: IMoment, toDateExclusive: IMoment): IStation {
    let validStationProps = station.properties
        .filter((p) => p.validFrom && !p.validFrom.isBefore(fromDateInclusive) && !p.validFrom.isAfter(toDateExclusive))
        .sortBy((prop) => prop.validFrom)
        .toList();
    const firstValid = station.properties.filter((p) => !p.validFrom?.isAfter(fromDateInclusive)).maxBy((prop) => prop.validFrom);
    if (firstValid) {
        validStationProps = validStationProps.push(firstValid);
    }
    if (validStationProps && validStationProps.count() > 0) {
        validStationProps = validStationProps.toSet().toList();
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const stationProps = <StationProperties>validStationProps.last()!.set("id", station.id);
        station = <IStation>station.merge(stationProps);
        station = <IStation>station.set("properties", validStationProps);
    }
    return station;
}

function getStationsForDateImpl(stations: Immutable.List<IStation>, atDate: IMoment | undefined): Immutable.List<IStation> {
    if (atDate && Moment.isMoment(atDate)) {
        stations = <Immutable.List<IStation>>stations.filter((s) => s.atDate(atDate).isActive);
    }
    const atDateOrToday = atDate && Moment.isMoment(atDate) ? atDate : TimeHelper.getCurrentSystemTime();
    stations = <Immutable.List<IStation>>stations.map((s) => {
        s = s.atDate(atDateOrToday);
        return <IStation>s.set("children", getStationsForDateImpl(s.children, atDate));
    });
    return stations;
}

export const getStationsForDate = _.memoize(getStationsForDateImpl, (stations: Immutable.List<IStation>, atDate: IMoment | undefined) => {
    const dateFormat = !atDate ? "null" : atDate.format("YYYYMMDD");

    return `${stations?.hashCode() || "null"}_${dateFormat}`;
});

// Returns all the stations in the argument-list, but with the isActive-information of the atDate.
export function getStationsInactivityForDate(stations: Immutable.List<IStation>, atDate: IMoment): Immutable.List<IStation> {
    const atDateOrToday = atDate && Moment.isMoment(atDate) ? atDate : TimeHelper.getCurrentSystemTime();
    stations = <Immutable.List<IStation>>stations.map((s) => {
        s = s.atDate(atDateOrToday);
        return <IStation>s.set("children", getStationsInactivityForDate(s.children, atDate));
    });
    return stations;
}

// Returns a string that gives an absolute ordering among the stations, with the tree structure in consideration.
export function getSortOrderByTreeStructure(station: IStation | undefined, stations: Immutable.List<IStation>): string | null {
    if (!station) {
        return null;
    }
    // Every station Id is padded so it is four digits long. This limits a restaurant to 10 000 stations.
    if (!station.parentNodeId) {
        return ("0000" + station.sortOrder).slice(-4);
    }
    return getSortOrderByTreeStructure(getStation(stations, station.parentNodeId), stations) + ":" + ("0000" + station.sortOrder).slice(-4);
}

/**
 * Get stations that exists for every day in the interval, e.g excluding stations that starts or ends in interval.
 * @export
 * @param {Immutable.List<IStation>} stations
 * @param {IMoment} fromDateInclusive
 * @param {IMoment} toDateExclusive
 * @returns {Immutable.List<IStation>}
 */
export function getStationsForDateInterval(stations: Immutable.List<IStation>, fromDateInclusive: IMoment, toDateExclusive: IMoment): Immutable.List<IStation> {
    if (fromDateInclusive && Moment.isMoment(fromDateInclusive) && toDateExclusive && Moment.isMoment(toDateExclusive)) {
        stations = stations.filter((s) => !s.propertiesInInterval(fromDateInclusive, toDateExclusive).some((p) => !p.isActive)).toList();
        stations = <Immutable.List<IStation>>stations.map((s) => {
            s = getVersionForInterval(s, fromDateInclusive, toDateExclusive);
            return <IStation>s.set("children", getStationsForDateInterval(s.children, fromDateInclusive, toDateExclusive));
        });
        return stations;
    } else {
        return getStationsForDate(stations, toDateExclusive ? Moment(toDateExclusive).subtract(1, "days") : fromDateInclusive);
    }
}

/**
 * Get stations that exists on some day in interval, e.g including stations that starts or ends in interval.
 * @export
 * @param {Immutable.List<IStation>} stations
 * @param {IMoment} fromDateInclusive
 * @param {IMoment} toDateExclusive
 * @returns {Immutable.List<IStation>}
 */
export function getStationsExistingInDateInterval(stations: Immutable.List<IStation>, fromDateInclusive: IMoment | undefined, toDateExclusive: IMoment): Immutable.List<IStation> {
    if (fromDateInclusive && Moment.isMoment(fromDateInclusive) && toDateExclusive && Moment.isMoment(toDateExclusive)) {
        stations = stations.filter((s) => s.propertiesInInterval(fromDateInclusive, toDateExclusive).some((p) => p.isActive)).toList();
        stations = <Immutable.List<IStation>>stations.map((s) => {
            s = getVersionForInterval(s, fromDateInclusive, toDateExclusive);
            return <IStation>s.set("children", getStationsExistingInDateInterval(s.children, fromDateInclusive, toDateExclusive)).set("isActive", true);
        });
        return stations;
    } else {
        return getStationsForDate(stations, toDateExclusive ? Moment(toDateExclusive).subtract(1, "days") : fromDateInclusive);
    }
}

export function getStationPath(stations: Immutable.List<IStation> | Immutable.OrderedMap<number, IStation>, stationId: number): Immutable.List<IStation> | null {
    let stationPath: Immutable.List<IStation> | null = null;
    stations.forEach((s) => {
        if (s.id === stationId) {
            stationPath = Immutable.List<IStation>([s]);
            return false;
        }
        const path = getStationPath(s.children, stationId);
        if (path) {
            stationPath = path.unshift(s);
            return false;
        }
        return true;
    });
    return stationPath;
}

export function getStationPathFromFlattenedStations(stations: Immutable.OrderedMap<number, IStation>, stationId: number): Immutable.List<IStation> | null {
    let station = stations.get(stationId);
    let stationPath = Immutable.List<IStation>();
    while (station) {
        stationPath = stationPath.insert(0, station);
        station = stations.get(station.parentNodeId);
    }
    return stationPath;
}

export function isHidden(station: IStation, hiddenStations: Immutable.Set<number>): boolean {
    if (!hiddenStations.contains(station.id)) {
        return false;
    }
    return !station.children.some((c) => !isHidden(c, hiddenStations));
}

export function isChildStation(station: IStation, childStationId: number): boolean {
    if (station.id === childStationId) {
        return true;
    } else {
        let result = false;
        station.children.forEach((child) => {
            if (!result && isChildStation(child, childStationId)) {
                result = true;
                return false; // Break the forEach loop
            }
            return true;
        });
        return result;
    }
}

export function rebuildTree(stations: Immutable.List<Station>): Immutable.List<Station> {
    const stationsIds = stations.map((s) => s.id).toSet();
    const rootStations = stations.filterNot((s) => stationsIds.contains(s.parentNodeId));
    const rootStationIds = rootStations.map((s) => s.id).toSet();
    const children = stations
        .filterNot((s) => rootStationIds.contains(s.id))
        .sortBy((x) => x.sortOrder)
        .toList();
    return rootStations.map((s) => <Station>s.set("children", rebuildTreeUnder(children, s.id))).toList();
}

function rebuildTreeUnder(stations: Immutable.List<Station>, parentNode: number): Immutable.List<Station> {
    const rootStations = stations.filter((s) => s.parentNodeId === parentNode);
    const children = stations
        .filter((s) => s.parentNodeId !== parentNode)
        .sortBy((x) => x.sortOrder)
        .toList();
    return rootStations.map((s) => <Station>s.set("children", rebuildTreeUnder(children, s.id))).toList();
}

export function hasPunchingEnabled(station: Station, date: IMoment) {
    return Boolean(station && station.propertiesAtDate(date).settings.enablePunching);
}

export function hasSellingEnabled(station: Station, date: IMoment) {
    return Boolean(station && station.propertiesAtDate(date).settings.isSelling);
}

export function isStationEnabled(stations: Immutable.List<Station>, stationId: number, date: IMoment) {
    const station = flattenStations(stations).get(stationId);
    if (!station) return false;
    return hasPunchingEnabled(station, date);
}

export function getScheduleEnabledStations(stations: Immutable.List<Station>, date: IMoment) {
    return flattenStations(getStationsForDate(stations, date))
        .filter((station) => hasPunchingEnabled(station, date))
        .toList();
}

export type ICostCenterField = "number" | "name";

export interface ICostCenterChange {
    costCenter: string;
    field: ICostCenterField;
}

export function getStationLevelMap(stations: Immutable.List<IStation>): Immutable.Map<number, number> {
    return _getStationLevelMap(stations, Immutable.Map<number, number>(), 0);
}

function _getStationLevelMap(stations: Immutable.List<IStation>, levelMapAck: Immutable.Map<number, number>, level: number): Immutable.Map<number, number> {
    stations.forEach((station: IStation) => {
        levelMapAck = _getStationLevelMap(station.children, levelMapAck.set(station.id, level), level + 1);
    });
    return levelMapAck;
}
